import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseBeanModel } from '@core/models/responsebean.model';
import { BaseApiService } from '@core/services/base-api.service';
import { Observable, of } from 'rxjs';
import { TProductTypes } from '../store/product/product.action';
import { IBooking, IPaginatedResponse } from '@core/interfaces';

@Injectable({
    providedIn: 'root'
})
export class ProductService extends BaseApiService {

    constructor(protected http: HttpClient) {
        super(http);
        this.apiEndpoint = 'products';
    }

    getProductDetail(): Observable<ResponseBeanModel> {
        // return this.makeRequest('GET', 'view', {});
        return of({ data: '', success: true });
    }

    getSubscriptionPlanByType(model: any): Observable<ResponseBeanModel> {
        return this.makeRequest('GET', '?type=' + model.type + '&subType=' + model.subType, {});
    }

    purchasePlanUsingGoodies(model: any): Observable<ResponseBeanModel> {
        return this.makeRequest('POST', 'productPurchase', model);
    }

    checkFilterPaymentForDog(): Observable<ResponseBeanModel> {
        return this.makeRequest('GET', 'paidFilter', {});
    }

    checkFilterPaymentForPerson(): Observable<ResponseBeanModel> {
        return this.makeRequest('GET', 'paidFilter', {});
    }


    sendIdsForManageSubscriptionOnPublish(model: any): Observable<ResponseBeanModel> {
        return this.makeRequest('POST', 'checkPaymentForAd', model);
    }

    purchaseFreeMatching(model: any): Observable<ResponseBeanModel>{
        return this.makeRequest('POST', 'purchaseFreeMatching', model);
    }

    purchaseHistoryData(page:number, length:number): Observable<ResponseBeanModel> {
        return this.makeRequest('GET', 'purchasedHistory', {page, length});
    }    

    bookingHistoryData(page:number, length:number): Observable<ResponseBeanModel> {
        return this.makeRequest('GET', 'bookingHistory', {page, length});
    }    

    getUserCurrentProductPaymentsData(): Observable<ResponseBeanModel> {
        return this.makeRequest('GET', 'purchase/active', {});
    }

    getBookingHistory(productTypes: Array<TProductTypes>, hideWithoutExpiry: boolean, pageNo: number, pageSize: number): Observable<IPaginatedResponse<IBooking>> {
        return this.makeRequest('GET', 'booking/history', {productTypes, hideWithoutExpiry, pageNo, pageSize});
    }  
}
