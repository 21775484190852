import { IPaginatedData } from "@core/interfaces";

export const getPaginatedInitialObject = <T>(): IPaginatedData<T> => ({
    records: [] as T[],
    total: 0,
    pageNo: 0,
    pageSize: 10,
    totalPages: 0,
    hasNextPage: false,
    hasPrevPage: false,
});