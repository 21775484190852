
export class GetProductDetailAction {
    static readonly type = '[Products] Get Products';
    constructor() { }
}

export class GetSubscriptionPlanByType {
    static readonly type = '[User] get subscription plan data by type';
    constructor(public model: { type: string, subType: string }) {
    }
}

export class PurchasePlanUsingGoodies {
    static readonly type = '[paid] purchase plan using goodies';
    constructor(public model: any) {
    }
}
export class CheckFilterPaymentForDog {
    static readonly type = '[Filter] check filter payment for dog';
    constructor() {
    }
}
export class CheckFilterPaymentForPerson {
    static readonly type = '[Filter] check filter payment for person';
    constructor() {
    }
}

export class SendIdsForManageSubscriptionOnPublish {
    static readonly type = '[Filter] send subscription ids for publish ad';
    constructor(public model:any) {
    }
}

export class GetPurchaseHistoryData {
    static readonly type = '[User] Get Purchase History Data';
    constructor(public page:number, public length:number) { }
}

export class GetBookingHistoryData {
    static readonly type = '[User] Get Booking History Data';
    constructor(public page:number, public length:number) { }
}

export class GetUserCurrentProductPaymentsData {
    static readonly type = '[User] Get User Current Product Payments Data';
    constructor() { }
}

export const ProductTypes = {
    ALL_INCLUSIVE_PURCHASE: 'allInclusivePurchase',
    CREDITS: 'credits',
    FREE_TRIAL_3D: 'FREE_TRIAL_3D',
} as const;

// Extract the keys as a type
export type TProductTypes = typeof ProductTypes[keyof typeof ProductTypes];

export class GetBookingsAction {
    static readonly type = '[Bookings] Get User Bookings';
    constructor(
        public productTypes: Array<TProductTypes>,
        public hideWithoutExpiry: boolean,
        public pageNo: number,
        public pageSize: number
    ) { }
}

export class GetMembershipPurchaseHistory {
    static readonly type = '[Bookings] Get Membership Purchase History';
    constructor(
        public pageNo: number = 1,
        public pageSize: number = 10,
    ) { }
}

export class GetGoodiesPurchaseHistory {
    static readonly type = '[Bookings] Get Goodies Purchase History';
    constructor(
        public pageNo: number = 1,
        public pageSize: number = 10,
    ) { }
}